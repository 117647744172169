<template>
    <!-- <section class="section-hero"> -->
    <section class="section-hero ps-2 pe-2 ps-sm-5 pe-sm-5">
        <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
            <div class="carousel-inner">
                <div style="height: 50%;" class="carousel-item active">
                    <img src="@/assets/img1-opt.jpg" class="d-block w-100" alt="AHDECO PORTADA 1">
                </div>
                <div class="carousel-item">
                    <img src="@/assets/img3-opt.jpg" class="d-block w-100" alt="AHDECO PORTADA 2">
                </div>

            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
        
        <!-- <div class="d-flex justify-content-center linea-fondo">
            <img src="@/assets/img1.jpg" class=" w-80 w-sm-100 d-sm-block" alt="">
        </div> -->

    </section>

    <section id="slogan" class="hidden ">
        <div class="container">
            <div class="row p-lg-5 p-sm-0">
                <h3 class="mt-5 bebas-neue-regular mb-5 text-center display-3 display-sm-5">¡Fortaleciendo Comunidades
                    Transformando
                    Vidas!</h3>


            </div>

        </div>
    </section>

    <section id="videos" class="hidden mb-5">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-4 col-12 ps-sm-5 ps-3 pe-3 pe-sm-0  ">
                <video controls autoplay muted class="w-100">
                    <source src="@/assets/vid1.mp4" type="video/mp4">
                    Tu navegador no soporta la reproducción de videos.
                </video>
            </div>
            <div class="col-sm-4 col-12 ps-4 pe-4">
                <video controls autoplay muted class="w-100 hide-on-mobile">
                    <source src="@/assets/vid2.mp4" type="video/mp4">
                    Tu navegador no soporta la reproducción de videos.
                </video>
            </div>
            <div class="col-sm-4 col-12 pe-5">
                <video controls autoplay muted class="w-100 hide-on-mobile">
                    <source src="@/assets/vid3.mp4" type="video/mp4">
                    Tu navegador no soporta la reproducción de videos.
                </video>
            </div>


        </div>
    </section>

    <section class="empleos row">
        <div class="col-sm-6 col-12 d-flex justify-content-center align-items-center">
            <p class="text-empleos">El programa <strong>“Emprendimiento Comunitario”</strong> apoya a poblaciones
                vulnerables mediante capacitación, financiamiento, asistencia técnica y acceso a mercados para fomentar
                el desarrollo local y mejorar la inserción laboral. Hemos creado más de 3,225 empleos.</p>
        </div>
        <div class="col-sm-6 col-12 cuadro d-flex align-items-center">
            <img src="@/assets/cantidad_empleos.jpeg" class=" img-empleos hidden ps-3 pe-3 ps-sm-0 pe-sm-0"
                id="img-empleos" alt="EMPLEOS AHDECO">

        </div>
    </section>

    <section id="noticia" class="hidden  m-1 p-1 p-sm-5 p-sm-5 text-center">

        <h2 class="display-5 bebas-neue-regular m-5">Últimas Noticias</h2>
        <div class="row">
            <div class="col-sm-4 col-12 d-flex justify-content-center mb-sm-0 mb-5 p-0 p-sm-4">
                <NoticiaCardHome class="" imageSrc="noti6.jpg" title="¡Compartimos con los estudiantes!" description="Compartimos con los estudiantes de la 
Universidad José Cecilio Del Valle en su 'Expo Solidaridad' para conectar con voluntarios y así... " 
idArticulo="3" />
            </div>
            <div class="col-sm-4 col-12 d-flex justify-content-center mb-sm-0 mb-5 p-0 p-sm-4">
                <NoticiaCardHome imageSrc="noti5.jpeg"
                    title="Encantos de Mi Tierra de Yuscarán, El Paraiso"
                    description="el marco del cumplimiento al convenio de cooperación que se tiene con la Alcaldía de Yuscarán se desarrolló la Asamblea General de la Empresa de Servicios Múltiples..." 
                    idArticulo = "5" />
            </div>
            <div class="col-sm-4 col-12 d-flex justify-content-center mb-sm-0 p-0 p-sm-4">
                <NoticiaCardHome imageSrc="noti6.jpeg"
                    title="Firma de Convenio Marco de Cooperación para el fortalecimiento del sector productivo, empresarial y social."
                    description="Este día celebramos la firma del convenio marco de cooperación entre la Asociación Hondureña para el Desarrollo Integral Comunitario (AHDECO) y la Universidad
Metropolitana de Honduras (UMH)..."  idArticulo = "6"/>
            </div>
        </div>

    </section>
    <section class="container mapa-contenedor">
        <div class="row hidden" id="mapa">
            <MapaLaura />
        </div>
    </section>




</template>

<script>
// script.js
import MapaLaura from './mapa/mapaLaura.vue';

import NoticiaCardHome from "@/components/NoticiaCardHome.vue";


export default {
    name: 'BodyApp',
    mounted() {

    },
    components: {
        NoticiaCardHome,
        MapaLaura,
    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Mobile */
@media only screen and (max-width: 768px) {

    .hide-on-mobile {
        display: none;
    }

}

.linea-fondo {
    /* background-image: url('@/assets/blur.jpg');
            background-size: cover;
            background-position: center; */
    background-color: rgb(223, 222, 222);
}

.img-empleos {
    width: 50%;
    border-radius: 5px;
    position: absolute;
    right: 90px;
    /* top: 1750px; */
    margin-top: 30px;

}

.carousel-inner {
    border-radius: 20px;
}



.empleos {
    margin-top: 80px;
    height: 62vh;
    display: flex;
    align-items: center;
    /* justify-content: center; */

}

.cuadro {
    background-color: rgb(225, 225, 225);
    height: 100%;
    border-radius: 5px;

}

strong {
    font-weight: 700;
}

.text-empleos {
    margin: 0px 120px 0px 50px;
    text-align: justify;

}

video {
    border-radius: 7px;
}

#mapa {
    height: 760px;

}

.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  color:#158B37 ;
}


/* Estilo para pantallas más pequeñas */
@media only screen and (max-width: 1200px) {
    #mapa {
        height: 66vh;
        /* 60% de la altura de la ventana */
    }

    .text-empleos {
        margin: 20px;
        text-align: justify;

    }

    .img-empleos {
        width: 100%;
        /* border-radius: 5px; */
        position: relative;
        right: 0px;
        /* top: 1750px; */
        /* margin-top: 30px; */

    }

    #noticia {
        margin: 30px !important;
        padding-inline: 0px !important;
    }

    .empleos {
        margin-top: 0px;
        height: 100%;
        display: flex;
        align-items: center;
        /* justify-content: center; */

    }

    .cuadro {
        background-color: #F7F7F8;
        height: 0%;
        border-radius: 5px;

    }


    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-top: var(--bs-gutter-y);
    }
}

@media only screen and (max-width: 990px) {
    #mapa {
        height: 50vh;
        /* 50% de la altura de la ventana */
    }


}

@media only screen and (max-width: 767px) {
    #mapa {
        height: 36vh;
        /* 40% de la altura de la ventana */
    }


}

@media only screen and (max-width: 572px) {
    #mapa {
        height: 38vh;
        /* 30% de la altura de la ventana */
    }
}

@media only screen and (max-width: 470px) {
    #mapa {
        height: 30vh;
        /* 30% de la altura de la ventana */
    }
}

.mapa-contenedor {
    padding-bottom: 150px;
    padding-inline: 10px;
}
</style>
