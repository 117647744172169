<template>


    <div class="container text-center">
        <h2 class="display-3 bebas-neue-regular mb-5 mt-3">Noticias</h2>
        <div class="row">
            <div class="col-sm-4 col-12 noticia">
                <NoticiaCardHome title="Elaboración Piñatas de Cartón y Bordado de Manta" imageSrc="noti1.jpeg"
                description="Hemos cultivado habilidades artísticas y fortalecido la confianza de nuestras participantes..."   idArticulo="1"/>
            </div>
            <div class="col-sm-4 col-12 noticia">
                <NoticiaCardHome title="Graduación del Proyecto de Emprendimiento Comunitario" imageSrc="noti2.jpeg"
                description="Capacitando con más de 18 horas de formación empresarial a 34 emprendedores y productores..."     idArticulo="2" />
            </div>
            <div class="col-sm-4 col-12 noticia">
                <NoticiaCardHome title="Expo Solidaridad" imageSrc="noti4.jpeg"   idArticulo="3" 
                description="Compartimos con los estudiantes de la Universidad José Cecilio Del Valle en su Expo Solidaridad..."/>
            </div>
            <!-- --------------------------------- -->
            <div class="col-sm-4 col-12 noticia">
                <NoticiaCardHome title="Proyecto de Emprendimiento Comunitario en alianza con la Alcaldia Municipal de Dulce Nombre de Culmi, Olancho." imageSrc="noti3.jpeg" 
                description="Este proyecto conformado por tres etapas tiene como objetivo potenciar y maximizar la INVERSIÓN MUNICIPAL..."  idArticulo="4" />
            </div>

            <div class="col-sm-4 col-12 noticia">
                <NoticiaCardHome
                    title="Encantos de Mi Tierra de Yuscarán, El Paraiso"
                    imageSrc="noti5.jpeg" description="Brindamos asistencia legal a la Asamblea General “Encantos de Mi Tierra de Yuscarán, El Paraiso. En el marco del cumplimiento al convenio de cooperación que se tiene con la Alcaldía de Yuscarán..."   idArticulo="5" />
            </div>
            <div class="col-sm-4 col-12 noticia">
                <NoticiaCardHome
                    title="Programa de Colaboración entre la AHDECO Y UMH."
                    imageSrc="noti6.jpeg" description="Firma de Convenio Marco de Cooperación para el fortalecimiento del sector productivo, empresarial y social de la economia a través del Programa de Colaboración entre la AHDECO Y UMH..."  idArticulo="6" />

            </div>
        </div>
    </div>

    
</template>

<script>
// import NoticiaCard from './NoticiaCard.vue'
import NoticiaCardHome from '@/components/NoticiaCardHome.vue';

export default {
    components: {
        NoticiaCardHome
    }
}
</script>


<style scoped>
.overlay {
    background-color: rgb(0, 0, 0);
}

.noticia {
    display: flex;
    justify-content: center;
    padding: 0;
    padding: 10px;
    /* height: 50vh; */
}

.text-justify {
  text-align: justify;
}
</style>