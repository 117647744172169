<template>
    <div class="container mapa-contenedor">
        <div class="row">
            <div
                class="col-sm-6 col-12 pb-5 contactanos text-center d-flex align-items-center flex-column justify-content-center ">
                <div class="formulario mt-5">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Correo Electrónico</label>
                        <input type="email" class="form-control" v-model="email" id="email"
                            placeholder="usuario@ejemplo.com" required>

                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Mensaje</label>
                        <textarea v-model="contenido" class="form-control" id="contenido" rows="3"></textarea>
                    </div>
                    <button class="btn btn-primary" type="button" @click="enviarCorreo()">Enviar Correo</button>
                </div>

                <p class="mt-4">Col. Rubén Darío, Casa 0592 Tegucigalpa, Honduras</p>
                <p>info@ahdecohn.org</p>
                <p>2242-5780</p>
            </div>
            <div class="col-sm-6 col-12 d-flex align-items-center flex-column justify-content-center">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3254.0186182872103!2d-87.1925338795!3d14.09475517711527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f6fa2c562e9cf89%3A0xa0738e1022af0daa!2s3RW4%2B6CG%2C%20Tegucigalpa%2C%20Francisco%20Moraz%C3%A1n!5e0!3m2!1ses!2shn!4v1724361708027!5m2!1ses!2shn"
                    width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>

        <section class="row" id="mapa">
            <MapaLaura />
        </section>
    </div>
</template>

<script>
import MapaLaura from './mapa/mapaLaura.vue';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            contenido: "",
            email: "",
        }
    },
    components: {
        MapaLaura
    },
    methods: {
        enviarCorreo() {
            let email = document.getElementById("email");
            let contenido = document.getElementById("contenido");

            // Validar el email
            if (!this.esCorreoValido(email.value)) {
                email.style.border = "2px solid #E94434";
            } else {
                email.style.border = ""; // Reinicia el borde si es válido
            }

            // Validar el contenido
            if (!this.tieneMinimoTresLetras(contenido.value)) {
                contenido.style.border = "2px solid #E94434";
            } else {
                contenido.style.border = ""; // Reinicia el borde si es válido
            }

            if (this.esCorreoValido(this.email) &&
                this.tieneMinimoTresLetras(this.contenido)) {
                emailjs.init("9D_viF6YKvAEF0eDM"); // Asegúrate de reemplazar "TU_USER_ID" con tu User ID

                emailjs.send("service_6xaa9ya", "template_0zsa4mf", {
                    emailjs_email: this.email,
                    emailjs_message: this.contenido,
                })
                    .then(() => {

                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Tu correo ha sido enviado correctamente",
                            showConfirmButton: false,
                            timer: 1500
                        });

                        this.email=''
                        this.contenido=''
                        // console.log('SUCCESS!', response.status, response.text);
                    }, (error) => {
                        // console.log('FAILED...', error);
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: error,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    });


            }




        },
        esCorreoValido(correo) {
            // Expresión regular para validar correo electrónico
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(correo);
        },
        tieneMinimoTresLetras(texto) {
            // Expresión regular para validar que haya al menos tres letras
            const regex = /[a-zA-Z].*[a-zA-Z].*[a-zA-Z]/;
            return regex.test(texto);
        }

    }
}
</script>

<style scoped>
.contactanos {
    background-color: #e9e9e9;
    border-radius: 5px;
}

iframe {
    border-radius: 5px;
}

.formulario {
    width: 80%;
    border-radius: 10px;
    padding: 40px;
    background-color: #158b36c2;
    color: white;
}

#mapa {
    height: 760px;

}

/* Estilo para pantallas más pequeñas */
@media only screen and (max-width: 1200px) {
    #mapa {
        height: 66vh;
        /* 60% de la altura de la ventana */
    }
}

@media only screen and (max-width: 990px) {
    #mapa {
        height: 50vh;
        /* 50% de la altura de la ventana */
    }

    iframe {
        margin-block: 50px;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    #mapa {
        height: 36vh;
        /* 40% de la altura de la ventana */
    }
}

@media only screen and (max-width: 572px) {
    #mapa {
        height: 38vh;
        /* 30% de la altura de la ventana */
    }
}

@media only screen and (max-width: 470px) {
    #mapa {
        height: 30vh;
        /* 30% de la altura de la ventana */
    }
}

.mapa-contenedor {
    padding-bottom: 120px;
}
</style>
