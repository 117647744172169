<template>
  <Header />
  <div class="container-fluid">
    <router-view v-slot="{ Component }" :key="componentKey">
      <keep-alive include="BooksComposition">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
  <Footer />
</template>

<script>
import Header from "./components/HeaderApp.vue"
import Footer from "./components/Footer.vue"
import { store } from './components/store.js'

const getCookie = (name) => {
  return document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, "");
}

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      store,
      componentKey: 0,
    }
  },
  beforeMount() {
    // check for a cookie
    let data = getCookie("_site_data");

    if (data !== "") {
      let cookieData = JSON.parse(data);

      // update store
      store.token = cookieData.token.token;
      store.user = {
        id: cookieData.user.id,
        first_name: cookieData.user.first_name,
        last_name: cookieData.user.last_name,
        email: cookieData.user.email,
      }
    }
  },
  mounted() {
    if (window.innerWidth < 992) {
      this.trancitionUpInit("videos");
      this.trancitionUpInit("slogan");
    } else {
      this.trancitionUp("videos");
      this.trancitionUp("slogan");
      
    }

    this.trancitionUpInit("emprendimiento");
    
    this.trancitionUp("img-empleos")
    this.trancitionUp("valores")
    this.trancitionUp("noticia")
    this.trancitionUp("mapa")
    this.trancitionUp("gobierno")
    this.trancitionUp("comunidad")

  },

  methods: {
    forceUpdate() {
      this.componentKey += 1;
    },
    trancitionUp(element) {
      document.addEventListener('scroll', () => {
        this.checkVisibility(element);
      });
    },
    trancitionUpInit(element) {
      // Cargar cuando la página se inicia
      document.addEventListener('DOMContentLoaded', () => {
        this.checkVisibility(element);
      });

      // También mantener la función en scroll
      document.addEventListener('scroll', () => {
        this.checkVisibility(element);
      });
    },

    checkVisibility(element) {
      var section = document.getElementById(element);

      if (section) { // Verifica si el elemento existe
        var sectionPosition = section.getBoundingClientRect().top;
        var screenPosition = window.innerHeight;

        if (sectionPosition < screenPosition) {
          section.classList.add('visible');
        }
      }
    },
  }
}
</script>

<style>
#sqrx-content-container {
  width: 0 !important;
}

.squarex_ext_modal {
  width: 0 !important;
}

.hidden {
  opacity: 0;
  transform: translateY(100px);
  /* Comienza 100px más abajo  */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.row>* {
  /* flex-shrink: 0; */
  /* width: 100%; */
  /* max-width: 100%; */
  padding-right: 0;
  padding-left: 0;
  /* margin-top: var(--bs-gutter-y); */
}
</style>