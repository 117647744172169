<template>
    <div class="container-logo">
        <img src="@/assets/logoMapa.png" class="mapa" :alt="alt" :style="{ top: top + '%', left: left + '%' }">

        <div class="hover-menu mapa" :style="{ top: top - 16 + '%', left: left - 3 + '%' }">
            <div class="sucursal bebas-neue-regular">{{ sucursal }}</div>
            <div class="contacto d-flex flex-column">
                <span class="telefono">+504 {{ telefono }}</span>
                <span class="asesor">{{ asesor }}</span>
                <button class="btn btn-primary" @click="copiarTelefono(this.telefono)">Copiar teléfono</button>
                <a class="btn btn-success mt-1 " target="_blank"
                    :href="`https://api.whatsapp.com/send/?phone=${this.telefono}&text&type=phone_number&app_absent=0`"><i
                        class="fa-brands fa-whatsapp"></i> Whatsapp</a>
                <!-- <a class="btn btn-success " href="https://www.google.com/maps?q=14.769825,-88.7766479&z=17&hl=es"><i class="fa-solid fa-map-location-dot"></i> Ubicación</a> -->
            </div>

        </div>

    </div>



</template>
<script>
import Swal from 'sweetalert2';

export default {
    name: 'LogoMapa',
    props: {
        alt: {
            type: String,
            default: 'Icono logo Ahdeco'
        },
        top: {
            type: Number,
            default: 0
        },
        left: {
            type: Number,
            default: 0
        },
        telefono: {
            type: String,
            required: true
        },
        sucursal: {
            type: String,
            required: true
        },
        asesor: {
            type: String,
            required: true
        }
    },
    methods: {
        copiarTelefono(telefono) {
            // Crear un elemento de entrada temporal para copiar el texto
            const inputTemporal = document.createElement('input');
            inputTemporal.value = telefono;
            document.body.appendChild(inputTemporal);

            // Seleccionar el contenido del input
            inputTemporal.select();
            inputTemporal.setSelectionRange(0, 99999); // Para dispositivos móviles

            // Copiar el texto al portapapeles
            document.execCommand('copy');


            // Eliminar el input temporal del DOM
            document.body.removeChild(inputTemporal);
            Swal.fire({
                position: "center",
                icon: "success",
                title: 'Copiado al portapapeles con éxito',
                showConfirmButton: false,
                timer: 1500
            });
            // alert(`Número ${telefono} copiado al portapapeles con éxito`);
        }
    }
}
</script>
<style scoped>
.mapa {
    position: absolute;
    width: auto;
    /* Ajusta el tamaño según sea necesario */
    height: 10%;
}

.hover-menu {
    display: none;
    /* position: absolute; */
    /* top: -100%;  */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    z-index: 10;
    height: 200px;
}



.container-logo:hover .hover-menu {
    display: block;

}

.sucursal {
    font-size: 20px;
}

.telefono {
    /* color: blue; */
    font-size: 15px;

}

.asesor {
    color: rgb(0, 0, 0);
    margin-bottom: 5px;
    font-weight: 600;
}
</style>