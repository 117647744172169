<template>
    <!-- <div class="d-flex justify-content-center" style="width:100%; ">
        <img src="@/assets/EnConstruccion-transformed.jpeg" style="border-radius: 15px; width:65%;" alt="Página Ahdeco">
    </div> -->


    <div class="container">
        <h2 class="mb-5 display-3 bebas-neue-regular mt-4 text-center">Programas y proyectos</h2>

        <div class="row hidden" id="emprendimiento">
            <div class="col-12 col-sm-6 d-flex text-justify justify-content-center flex-column p-5 emprendimiento-info">
                <h4>EMPRENDIMIENTO COMUNITARIO</h4>
                <p>Apoyar a la población vulnerable, de extrema pobreza y sobrevivientes de violencia, potenciándoles a
                    ser
                    emprendedores locales a través de capacitación y mejora de competencias, acceso a financiamiento,
                    asistencia
                    técnica y mentoría, acceso a mercados promoviendo el desarrollo económico local y fortalecer el
                    encadenamiento productivo y la inserción laboral. </p>
            </div>
            <div class="col-12 col-sm-6 p-5">
                <img class="img-empre" src="@/assets/emprendimiento-comunitario.jpeg"
                    alt="emprendimiento comunitario ahdeco">
            </div>
        </div>
        <div class="row hidden" id="gobierno">
            <div class="col-12 order-2 order-sm-1 col-sm-6 p-5 ">
                <img class="img-empre" src="@/assets/gobierno.jpeg" alt="emprendimiento comunitario ahdeco">
            </div>
            <div class="col-12 order-1 order-sm-2 col-sm-6 d-flex text-justify justify-content-center flex-column p-5 gobierno-info">
                <h4 class="mt-3">GOBIERNO MUNICIPAL TRANSPARENTE, ACTIVO Y PARTICIPATIVO</h4>
                <p>Fortalecer las capacidades municipales a través de programas de capacitación y asistencia técnica en
                    la
                    formulación de la planificación, ejecución, liquidación y rendición de cuentas, promoviendo la
                    transparencia, la eficiencia y la participación ciudadana en la gestión municipal.
                </p>
            </div>

        </div>
        <div class="row hidden" id="comunidad">
            <div class="col-12 col-sm-6 d-flex text-justify justify-content-center flex-column p-5 comunidad-info">
                <h4 class="mt-3"> MI COMUNIDAD UNIDA </h4>
                <p>Promover el desarrollo social y la inclusión en la comunidad, a través de programas y proyectos que
                    fortalezcan la educación, la salud sexual y reproductiva, la prevención de la violencia doméstica,
                    la
                    contención de la migración y la atención al migrante retornado, la igualdad de género y la
                    participación
                    ciudadana.
                </p>
            </div>
            <div class="col-12 col-sm-6 p-5">
                <img class="img-empre" src="@/assets/comunidad.jpeg"
                    alt="emprendimiento comunitario ahdeco">
            </div>
        </div>



    </div>

</template>

<script>
export default {

}
</script>

<style>
.text-justify {
    text-align: justify;
}

.img-empre {
    width: 100%;
    border-radius: 5px;
}

.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  color:#158B37 ;
}

.emprendimiento-info{
    /* border-bottom: 4px solid #158B37; */      
    border-left: 4px solid #F6A500;
    /* border-radius: 5px; */
}
.gobierno-info{
    /* border-bottom: 4px solid #1E83C5;        */
    border-right: 4px solid #1E83C5;
    /* border-radius: 5px; */
}
.comunidad-info{
    /* border-bottom: 4px solid #E20413;    */    
    border-left: 4px solid #F6A500;
    /* border-radius: 5px; */
}
</style>