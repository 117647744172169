<template>
  <!-- <div class="d-none nav-false" style="height: 141.6x; width: 100%;"></div> -->

  <header class="header">
    <nav class="navbar-superior d-flex justify-content-end align-items-center ">

      <p class="text-nav-sup"><i class="fa-solid fa-envelope me-2 "></i>info@ahdecohn.org</p>
      <p class="text-nav-sup"><i class="fa-solid fa-phone ms-4 me-2 "></i>2242-5780</p>

    </nav>
    <nav class="main-nav container-fluid nav-s">
      <div id="main-nav" class="row d-flex justify-content-between align-items-center">
        <div id="logo" onclick="home()" class="col-9 col-sm-3 d-flex align-items-center justify-content-center logo">
          <!-- <img id="logo-nav" class="me-3 col-6" style="width: 200px;" src="/img/LogoAMpeq.png" alt=""> -->
          <div class="tracking-in-expand d-flex">
            <a class="navbar-brand brand-logo me-5" href="/"><img src="@/assets/logo.png" style="height: 70px; "
                class="me-2 ms-5" alt="logo" /></a>
          </div>
        </div>

        <div class="col-sm-7 col-12 d-flex align-items-center justify-content-center center-nav">
          <a class="main-nav-link text-center" href="" onclick="closef()"></a>

          <!-- <a class="circulo element" href="" ></a> -->
          <router-link to="/" class="main-nav-link pe-3 text-center" id="inicio" @click="closef()">Inicio </router-link>

          <router-link class="main-nav-link pe-4 " id="quienes-somos" to="/quienes-somos" onclick="closef()">
            <p class="text-center">¿Quiénes Somos?</p>
          </router-link>


          <div class="dropdown">

            <router-link class="main-nav-link pe-4" id="que-hacemos" to="/que-hacemos" onclick="closef()">
              <p class="text-center">¿Qué hacemos?</p>
            </router-link>

            <!-- <div class="dropdown-content">
              <a href="#">EMPRENDIMIENTO COMUNITARIO</a>
              <a href="#">GOBIERNO MUNICIPAL TRANSPARENTE, ACTIVO Y PARTICIPATIVO</a>
              <a href="#">MI COMUNIDAD UNIDA</a>
              <a href="#">CONSERVACIÓN Y RESILIENCIA AMBIENTAL DE MI COMUNIDAD</a>
            </div> -->
          </div>
          <router-link class="main-nav-link pe-4" id="noticias" to="/noticias" onclick="closef()">Noticias</router-link>
          <router-link class="main-nav-link pe-4" id="contactanos" to="/contactanos"
            onclick="closef()">Contáctanos</router-link>
          <!-- <router-link class="dropdown-item" to="/">Contáctanos</router-link> -->
        </div>

        <div class="col-2 d-flex justify-content-center center-nav final-nav">
          <div class="iconos-apps">
            <a href="https://www.facebook.com/ahdecohonduras/" target="_blank" class="me-3 fa-brands fa-facebook"></a>
          </div>

          <div class="iconos-apps">
            <a href="https://twitter.com/ahdecohn" target="_blank" class="me-3 fa-brands fa-x-twitter"></a>
          </div>
          <div class="iconos-apps">
            <a href="https://www.instagram.com/ahdecohn/" target="_blank" class="fa-brands fa-instagram"></a>
          </div>
        </div>
        <button class="col-3 btn-mobile-nav" onclick="openNav()" id="btnMobileNav">
          <i class="fa-solid fa-bars"></i>
        </button>
      </div>
    </nav>
    <!-- <div class="franja"></div> -->
  </header>
</template>

<script>

// import router from './../router/index.js'

export default {
  data() {
    return {
      text: ''
    }
  },
  methods: {
    redireccionar() {
      console.log('Hola');
    }
  },
  // Lógica de Vue para el componente Header
  mounted() {
    let mainNav = document.querySelector(".main-nav");
    let centerNav = document.querySelector(".center-nav");
    let finalNav = document.querySelector(".final-nav");

    (mainNav.innerHTML += `<div id="bot-nav" class="row d-none">\n${centerNav.innerHTML}    \n<div class="col d-flex justify-content-center ">\n${finalNav.innerHTML} \n</div>  \n \n</div>`),



      window.addEventListener("resize", function () {

        //     let e = document.getElementById("bot-nav");
        // window.innerWidth > 992 && open == !1
        //   ? document.getElementById("bot-nav").classList.add("d-none")
        //   : (document.getElementById("bot-nav").classList.remove("d-none"),
        //     (e.style.backgroundColor = "#2d2e32"),
        //     e.classList.add("d-none"));
        let e = document.getElementById("bot-nav");
        (document.getElementById("bot-nav").classList.remove("d-none"),
          (e.style.backgroundColor = "#2d2e32"),
          e.classList.add("d-none"));



      });
    // var open = !1;
  },
  watch: {
    $route(to, from) {
      console.log('Route changed from', from.path, 'to', to.path);

      const value = to.path; // Puedes cambiar esto a cualquier valor que necesites

      switch (value) {
        case '/':
          document.getElementById('inicio').style.color = '#F6A500';
          break;
        case '/quienes-somos':
          document.getElementById('quienes-somos').style.color = '#F6A500';
          break;
        case '/que-hacemos':
          document.getElementById('que-hacemos').style.color = '#F6A500';
          break;
        case '/noticias':
          document.getElementById('noticias').style.color = '#F6A500';
          break;
        case '/contactanos':
          document.getElementById('contactanos').style.color = '#F6A500';
          break;
        default:
        // document.getElementById('inicio').style.color = 'black';
      }

    }
  }
};
</script>

<style scoped>
/* Estilos específicos del encabezado */
.tracking-in-expand {
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;

  font-size: 30px;
  color: rgb(0, 145, 255);
  /* color: rgb(48, 97, 255); */
}

/* ----------------------------------------------
* Generated by Animista on 2024-4-5 18:52:19
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.color-first {
  color: rgb(122, 179, 255);
}


.circulo {
  display: inline-block;
  position: absolute;
  z-index: -100;
  /* Valor más bajo para enviar hacia atrás */
  border-radius: 50%;
  /* Hace que el borde sea redondo, creando un círculo */
  /* background-color: rgb(98, 184, 255); */
  background-color: rgb(0, 145, 255);

  /* Define el color de fondo del círculo */
  color: white;
  /* Color del texto dentro del círculo */
  padding: 10px;
  /* Espaciado interno para el texto */
  width: 150px;
  /* Ancho del círculo */
  height: 150px;
  /* Altura del círculo */
  text-align: center;
  /* Centra el texto vertical y horizontalmente */
  line-height: 30px;
  /* Ajusta la altura de línea para centrar verticalmente el texto */
  cursor: pointer;
}

.circulo:hover {
  height: 250px;
  width: 250px;
  margin: 0 auto;
  background-color: rgb(45, 160, 255);
  animation-name: stretch;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}

@media only screen and (max-width: 992px) {
  .circulo {
    display: none;
  }
}

@keyframes stretch {
  0% {
    transform: scale(0.6);
    background-color: rgb(0, 157, 214);
    border-radius: 100%;
  }

  50% {
    background-color: rgb(0, 145, 255);
  }

  100% {
    transform: scale(0.7);
    background-color: rgb(5, 163, 255);
  }
}

.main-nav-link {
  color: rgb(75, 75, 75);
  font-size: 17px;
}

.main-nav-link:hover {
  color: #1f1400;
  /* color: #1E83C5; */
  font-size: 17px;
}

.iconos-apps {
  font-size: 20px;
}

.iconos-apps a {
  color: black;
  /* Color negro por defecto */
  text-decoration: none;
}

.iconos-apps a:hover {
  color: #1f1400;
  /* Color negro por defecto */
}

/* Media query para dispositivos móviles */
@media only screen and (max-width: 768px) {
  .iconos-apps a {
    color: white;
    /* Cambiar color a blanco en dispositivos móviles */
  }

}

.navbar-superior {
  background-color: #158B37;
  height: 50px;
  color: white;
  padding-right: 350px;
}

.main-nav {
  height: 100%;
}

.main-nav-link p {
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.text-nav-sup {
  font-size: 16px;
}

@media (max-width: 768px) {
  .text-nav-sup {
    display: none;
  }
}

@media (max-width: 992px) {
  .main-nav-link {
    color: white !important;
  }

  .iconos-apps a {
    color: white !important;
  }

  .main-nav-link {
    padding: 0 !important;
  }

  .main-nav-link::after {
    display: none;
  }
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.dropdown-content {
  display: none;
  position: absolute;
  margin-top: 320px;
  border-radius: 5px;
  font-size: 12px;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 6;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;

}

.dropdown:hover .dropdown-content {
  /* display: block; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
}

/* Linea Animada */

/* Estilo general para los router-link */
.main-nav-link {
  position: relative;
  /* Necesario para posicionar el pseudo-elemento */
  text-decoration: none;
  /* Quitar el subrayado por defecto */
  color: inherit;
  /* Heredar el color del texto */
  padding-bottom: 5px;
  /* Espacio para el subrayado */
}

/* Pseudo-elemento para el subrayado animado */
.main-nav-link::after {
  content: "";
  /* Necesario para crear el pseudo-elemento */
  position: absolute;
  /* Posición absoluta respecto al elemento principal */
  left: 0;
  /* Comienza desde el borde izquierdo */
  bottom: 0;
  /* Posicionado en la parte inferior */
  width: 0;
  /* Inicialmente no visible */
  height: 2px;
  /* Altura del subrayado */
  background-color: #F6A500;
  /* Color del subrayado */
  transition: width 0.6s ease;
  /* Animación suave del ancho */
}



/* Estilo para el efecto hover */
.main-nav-link:hover::after {
  width: 90%;
  /* Expande el subrayado a todo el ancho del enlace */
}
</style>
