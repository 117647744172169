<template>
  <div class="franja-saber">
    <img src="@/assets/img111.jpg" alt="">
    <div class="overlay"></div>
    <div class="text">¿Quién es AHDECO?</div>
  </div>

  <section class="container mt-5 p-0 p-lg-5 " >
    <div class="row text-center">
      <p class="white" id="quienes-somos">
        Somos una asociación no gubernamental sin fines de lucro, que promueve el Desarrollo Integral Comunitario (DIC)
        con un enfoque y proceso que busca mejorar la calidad de vida de una comunidad en su conjunto en especial de las
        <strong>MUJERES Y JÓVENES.</strong> Entendiendo que el desarrollo no se limita únicamente al crecimiento
        económico, por lo que el
        abordaje incluye aspectos sociales, culturales, ambientales y políticos. a través de la ejecución de programas y
        proyectos que permita lograr un progreso genuino y sostenible para la comunidad.

      </p>
    </div>
    <div class="row box-2-l tree-box p-5">
      <div class="col-lg-6 col-12">
        <div class="text-center p-3">
          <h3 class=" mision">Misión</h3>
          <!-- <i class="fa-solid iconos fa-bullseye white"></i> -->
          <!-- <img src="@/assets/mision.png" alt="misión ahdeco" class="m-3" style="width: 100px;"> -->
          <p class="white text-align-justify">Contribuimos al empoderamiento y la transformación de las comunidades,
            promoviendo la
            participación activa de sus habitantes, colaborando con los gobiernos locales para alcanzar un desarrollo
            sostenible, equitativo y transparente; centrado en el bienestar de sus habitantes.</p>
        </div>
      </div>

      <div class="col-lg-6 col-12">
        <div class=" text-center p-3">
          <h3 class=" vision">Visión</h3>
          <!-- <img src="@/assets/vision.png" alt="visión ahdeco" class="m-2" style="width: 100px;"> -->
          <p class="white text-align-justify">Ser al 2025, una ONG reconocida en el ámbito de desarrollo integral
            comunitario siendo
            agentes de cambio que trabajan en colaboración con los gobiernos locales para crear comunidades más
            resilientes, justas, prósperas, inclusivas y sostenibles, donde todos los habitantes tienen acceso a
            oportunidades de desarrollo y mejora en su calidad de vida.</p>
        </div>
      </div>

    </div>
    <div class="col-lg-12 col-12">
      <div class=" text-center p-3 hidden" id="valores">
        <h3 class="valores">Valores</h3>
        <!-- <i class="fa-solid iconos fa-gem white"></i> -->

        <div @click="openCasilla('c1')" class="valores-casillas white">
          <p>1. Compromiso con la comunidad</p>
          <i class="fa-solid fa-chevron-down pe-5"></i>
        </div>
        <div v-if="c1" class="mostrar-info">
          Nos comprometemos a escuchar y responder a las necesidades de las
          comunidades que servimos.
        </div>

        <div @click="openCasilla('c2')" class="valores-casillas white">
          2. Colaboración <br>
          <i class="fa-solid fa-chevron-down pe-5"></i>

        </div>
        <div v-if="c2" class="mostrar-info">
          Creemos en la colaboración efectiva con los gobiernos locales, otros actores en el
          territorio y partes interesas para lograr un impacto sostenible.
        </div>
        <div @click="openCasilla('c3')" class="valores-casillas white">
          3. Transparencia y responsabilidad <br>
          <i class="fa-solid fa-chevron-down pe-5"></i>

        </div>
        <div  v-if="c3" class="mostrar-info">
          Actuamos con integridad y rendimos cuentas de nuestras acciones y
          resultados.
        </div>
        <div @click="openCasilla('c4')" class="valores-casillas white">
          4. Innovación <br>
          <i class="fa-solid fa-chevron-down pe-5"></i>

        </div>
        <div v-if="c4" class="mostrar-info">
          Buscamos soluciones innovadoras y eficientes para los desafíos del desarrollo.
        </div>
        <div @click="openCasilla('c5')" class="valores-casillas white">
          5. Empoderamiento <br>
          <i class="fa-solid fa-chevron-down pe-5"></i>

        </div>
        <div v-if="c5" class="mostrar-info">
          Trabajamos para empoderar a las comunidades y promover la participación ciudadana.
        </div>

      </div>
    </div>
  </section>

</template>

<script>
export default {
  data() {
    return {
      c1: false,
      c2: false,
      c3: false,
      c4: false,
      c5: false,
    }
  },
  methods: {
    openCasilla(casilla) {
      switch (casilla) {
        case 'c1':
          if (this.c1 === true) {
            this.c1 = false
          } else {
            this.c1 = true
          }
          break;
        case 'c2':
          if (this.c2 === true) {
            this.c2 = false
          } else {
            this.c2 = true
          }
          break;
        case 'c3':
          if (this.c3 === true) {
            this.c3 = false
          } else {
            this.c3 = true
          }
          break;
        case 'c4':
          if (this.c4 === true) {
            this.c4 = false
          } else {
            this.c4 = true
          }
          break;
        case 'c5':
          if (this.c5 === true) {
            this.c5 = false
          } else {
            this.c5 = true
          }
          break;
        default:

      }



    }
  }
}
</script>

<style>
.franja-saber {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.franja-saber img {
  position: absolute;
  top: -190px;
  /* Mueve la imagen hacia arriba para mostrar solo la parte inferior */
  left: 0;
  filter: blur(10px);
  width: 100%;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Centra el texto */
  color: white;
  font-size: 45px;
  font-weight: bold;
  z-index: 2;
  /* Asegura que el texto esté delante de la imagen */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(1, 116, 8, 0.655);
  /* Capa oscura con opacidad */
  z-index: 2;
  /* Asegura que la capa oscura esté encima de la imagen */
}

.tree-box {
  margin-top: 60px;
  /* background-color: rgba(0, 0, 0, 0.114); */
  border-radius: 5px;
}

.box {
  height: 100%;
  width: 100%;
  background-color: rgb(31, 31, 31);
  background-color: rgb(31, 31, 31);
  padding-top: 40px !important;
  padding-bottom: 40px !important;

}

.box p {
  text-align: justify;
  padding: 6px;
}

.white {
  text-align: justify;

}

.iconos {
  font-size: 60px;
  margin: 20px;
}

.box-2 {

  /* border-top: 2px solid #F6A500; */
  /* Borde superior de 2 píxeles de grosor y color negro */
  /* border-left: 2px solid #1E83C5; */
  /* Borde izquierdo de 2 píxeles de grosor y color negro */
  border-radius: 10px;
  color: #000;

}

.box-2-l {

  /* border-top: 2px solid #F6A500; */
  /* border-bottom: 2px solid #1E83C5; */
  /* Borde superior de 2 píxeles de grosor y color negro */
  /* border-right: 2px solid #F6A500; */
  /* border-left: 2px solid #1E83C5; */
  /* Borde izquierdo de 2 píxeles de grosor y color negro */
  border-radius: 5px;
  color: #000;
  background-color: #ededed;

}

.box-2:hover {

  border-top: 2px solid #F6A500;
  /* Borde superior de 2 píxeles de grosor y color negro */
  border-left: 2px solid #1E83C5;
  /* Borde izquierdo de 2 píxeles de grosor y color negro */
  border-radius: 10px;
  color: #000;

}

.mision {
  color: #F49B1B;
  font-size: 45px;
}

.vision {
  color: #158B37;
  font-size: 45px;
}

.valores {
  /* color: #152f8b; */
  margin-top: 45px;
  font-size: 45px;
}

.valores-casillas {
  /* color: #152f8b; */
  width: 100%;
  height: 75px;
  background-color: #158B37;
  padding: 10px;
  color: white;
  font-size: 16px;
  border: 2px solid #ffffff;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.valores-casillas:hover {
  background-color: rgb(34, 184, 14);
}

.valores-casillas i {
  font-size: 25px;
}

.mostrar-info {
  padding: 15px;
}

@media only screen and (max-width: 1200px) {
  #quienes-somos{
    padding: 35px;
  }
}
</style>