<template>
  <div class="container bounceInUp">
    <div v-if="this.articulo != undefined" class="blog-post">
      <h1 class="title text-center m-4">
        {{articulo.titulo}}
      </h1>
      <div class="post-meta text-center">
        <span>By {{ this.articulo.autor }}</span>
        <span> | </span>
        <span>{{ this.articulo.fecha }}</span>
        <span> | </span>
        <span>{{ this.articulo.comentario }}</span>
      </div>
      <div class="post-images">
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">

          <div class="carousel-inner " id="imagen-articulo">
            <div v-for="(foto, index) in this.articulo.fotos" :key="index"
              :class="['carousel-item', { active: index === 0 }]">
              <img :src="require(`@/assets/articulos/${foto}`)" class="d-block w-100" alt="imagen de articulo ahdeco">
            </div>
          </div>

          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <p class="post-content mt-4 text-justify  p-4" id="contenido">
      
      </p>



    </div>
    <div v-else class="blog-post">
      <div class="alert alert-primary d-flex align-items-center" role="alert">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
          class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img"
          aria-label="Warning:">
          <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </svg>
        <div>
          El Articulo No Existe
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import articuloData from './articulosData.json';


export default {
  name: "BlogPost",
  data() {
    return {
      idArticulo: 0,
      articulo: {}
    }
  },
  beforeMount() {
    this.idArticulo = this.$route.params.id

    this.articulo = articuloData[this.idArticulo - 1]

    console.log(this.articulo);

    window.scroll({ top: 0 })

  },
  mounted() {
    if (this.articulo !== undefined) {
      let contenido = document.getElementById("contenido");
      if (contenido) {
        contenido.innerHTML += this.articulo.contenido;
      }
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.blog-post {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.post-meta {
  color: #777;
  font-size: 14px;
  margin-bottom: 20px;
}

.post-images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.image {
  max-width: 48%;
  border-radius: 8px;
}

.post-content {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;

}

.carousel {
  /* border-radius: 15px; */
  width: 70%;
}

.carousel-item img {
  border-radius: 5px;
}

.text-justify {
  text-align: justify;
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes bounceInUp {
  0%, 60%, 75%, 90%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, 3000px, 0);
  transform: translate3d(0, 3000px, 0);
  }
  60% {
  opacity: 1;
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  }
  75% {
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
  }
  90% {
  -webkit-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0);
  }
  100% {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  }
  }
  @keyframes bounceInUp {
  0%, 60%, 75%, 90%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, 3000px, 0);
  transform: translate3d(0, 3000px, 0);
  }
  60% {
  opacity: 1;
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  }
  75% {
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
  }
  90% {
  -webkit-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0);
  }
  100% {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  }
  }
</style>