// Define las flags globalmente
global.__VUE_OPTIONS_API__ = true;
global.__VUE_PROD_DEVTOOLS__ = false;
global.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

createApp(App).use(router).mount('#app')
