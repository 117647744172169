<template>
  
  <h2 class="mt-0 mt-sm-5 display-5 bebas-neue-regular text-center">Contacta a tu asesor más cercano</h2>
  <!-- <p class="mt-5 text-center">Selecciona y haz click en tu agencia</p> -->
  <p class="mt-2 mt-sm-2  text-center">Selecciona y pon el cursor sobre tu agencia</p>

  <div class="mapa-container">
    
    <!-- Francisco Morazan  -->
    <IconoMapa :top="47" :left="32.6" telefono="32315324" sucursal="Agencia Tegucigalpa" asesor="María Ramirez" />
    <!-- Colon -->
    <IconoMapa :top="10" :left="56" telefono="33407220" sucursal="Agencia Colón" asesor="Yésica Guiterrez"/>
    <!-- Olancho -->
    <IconoMapa :top="32.6" :left="50" telefono="33406718" sucursal="Agencia Olancho" asesor="Ana Hernandez"/>
       <!--EL Paraiso  -->
    <IconoMapa :top="53.6" :left="43" telefono="33401394" sucursal="Agencia El Paraiso" asesor="Nora Betancourth"/>
    <!-- Atlantida -->
    <IconoMapa :top="14" :left="34" telefono="33407220" sucursal="Agencia Atlantida" asesor="Yésica Gutierrez"/>
    <!-- Choluteca -->
    <IconoMapa :top="74" :left="33" telefono="33401394" sucursal="Agencia Choluteca" asesor="Nora Betancourth"/>
    <!-- Copan -->
    <IconoMapa :top="30" :left="5" telefono="33401668" sucursal="Agencia Copán" asesor="Mario Vega"/>
    <!-- Ocotepeque -->
    <IconoMapa :top="43" :left="2" telefono="33404290" sucursal="Agencia Ocotepeque" asesor="Thelma Acosta"/>
    <!-- Lempira -->
    <IconoMapa :top="49" :left="9" telefono="33401668" sucursal="Agencia Lempira" asesor="Mario Vega"/>
    <!-- Intibuca -->
    <IconoMapa :top="45" :left="16" telefono="33401668" sucursal="Agencia Intibuca" asesor="Mario Vega"/>
    <!-- Santa Barbara -->
    <IconoMapa :top="26" :left="13" telefono="31802863" sucursal="Agencia Santa Bárbara" asesor="Gloria Alvarado"/>
    <!-- Valle -->
    <IconoMapa :top="66" :left="26" telefono="33401394" sucursal="Agencia Valle" asesor="Nora Betancourth"/>
    <!-- Yoro -->
    <IconoMapa :top="25" :left="30" telefono="33401394" sucursal="Agencia Yoro" asesor="Nora Betancourth"/>
    <!-- Comayagua -->
    <IconoMapa :top="39" :left="24.5" telefono="33401394" sucursal="Agencia Comayagua" asesor="Nora Betancourth"/>
  
  </div>
</template>

<script>
import IconoMapa from './IconoMapa.vue';

export default {
  name: "MapaHonduras",
  components: {
    IconoMapa
  }
}
</script>

<style scoped>
.mapa-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/mapa.png');
  background-size: contain;
  /* Asegura que la imagen cubra todo el contenedor */
  background-position: center;
  /* Centra la imagen */
  background-repeat: no-repeat;
  /* Evita que la imagen se repita */
  /* margin-bottom: 160px; */
}


</style>
